import { useState, useMemo, useEffect } from 'react'
import moment from 'moment'
import ReactTooltip from 'react-tooltip'
import { Pill } from '../Pill'
import { ChevronLeftIcon } from '@heroicons/react/solid'
import { MobileView } from '../Mobile'
import { NavList, NavListItem } from '../List'
import { useDetectDevice } from '../../hooks/useDetectDevice'
import {
  ChevronDownIcon,
  ClockIcon,
  GlobeAltIcon,
  InformationCircleIcon,
} from '@heroicons/react/outline'
import { TimezonePicker } from '../TimezonePicker'
import { useQueryParam, StringParam } from 'use-query-params'
import { useDisclosure } from '../../hooks/disclosure'
import { Modal } from '../Modal'

const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
const DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
const NUMBER_OF_CELLS = 6 * 7
const DESKTOP_CELL_SIZE = '65px'
const MOBILE_CELL_SIZE = '50px'

function getNumberOfCells(today) {
  const month = today.clone()
  month.startOf('month')
  switch (month.daysInMonth()) {
    case 31:
      return month.day() >= 5 ? 6 * 7 : 5 * 7
    default:
      return 5 * 7
  }
}
export default function SlotPicker({
  onChange,
  calendar,
  month,
  placeholder,
  endOfDay,
  startOfDay,
  availability,
  onSelectSlot,
  onSelectDate,
  onChangeMonth,
  wrapInCard,
  ...rest
}) {
  const today = useMemo(() => moment().startOf('day'), [])
  const defaultDate = today // get from query string
  const [selectedDate, setDate] = useState(defaultDate)
  const [hasSelected, setHasSelected] = useState(false)
  const daysStartFrom = month.day()
  const { isMobile } = useDetectDevice()
  const [tz, setTzParam] = useQueryParam('tz', StringParam)
  const [timezone, setTimezone] = useState(tz || moment.tz.guess())
  const sameTimeZoneRequired = useDisclosure()
  const cells = new Array(getNumberOfCells(month))
    .fill({ type: 'blank' })
    .map((d, i) => {
      let date = month.clone().set('day', i)
      if (i >= daysStartFrom)
        return {
          type:
            date.month() > month.month() || date.year() > month.year()
              ? 'grey'
              : 'day',
          date,
        }

      date = month.clone().subtract(daysStartFrom - i, 'day')
      return { type: 'grey', date }
    })

  function onNextMonth() {
    onChangeMonth(
      month
        .clone()
        .startOf('month')
        .add(1, 'month')
        .startOf('month')
    )
  }

  function onPrevMonth() {
    if (month.isSame(today.clone().startOf('month'))) return
    onChangeMonth(
      month
        .clone()
        .startOf('month')
        .subtract(1, 'month')
        .startOf('month')
    )
  }
  const selectedSlots =
    availability?.[ // this map needs to change to localise
      selectedDate
        ? selectedDate?.format('DD/MM/YY')
        : defaultDate.format('DD/MM/YY')
    ] || []
  const cellSize = isMobile ? MOBILE_CELL_SIZE : DESKTOP_CELL_SIZE

  useEffect(() => {
    if (calendar.sameTimeZoneRequired && timezone !== calendar.timezone) {
      sameTimeZoneRequired.onOpen()
    }
  }, [calendar])
  return (
    <div
      className={
        wrapInCard
          ? 'flex-col flex bg-white rounded-lg p-1 md:p-2 shadow h-full md:h-auto w-full'
          : 'flex-col flex p-1 md:p-2 h-full md:h-auto w-full'
      }
    >
      <div className="flex-col md:flex-row flex w-full">
        <div
          className={`m-auto md:m-0 ${isMobile ? 'p-2' : 'p-2'} m:p-0 ${
            hasSelected ? 'hidden md:block' : ''
          }`}
        >
          <div
            className="md:mr-2"
            style={{ width: isMobile ? '350px' : '28rem' }}
          >
            <div className="flex justify-between items-center mb-2 pb-3 pl-2">
              <div>
                <span className="text-2xl font-bold text-gray-800">
                  {MONTH_NAMES[month.get('month')]}
                </span>
                <span className="ml-1 text-2xl text-gray-600 font-normal">
                  {month.format('YYYY')}
                </span>
              </div>
              <div>
                <button
                  type="button"
                  className="transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 p-1 rounded-full"
                  onClick={onPrevMonth}
                >
                  <svg
                    className="h-6 w-6 text-gray-500 inline-flex"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15 19l-7-7 7-7"
                    />
                  </svg>
                </button>
                <button
                  type="button"
                  className="transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 p-1 rounded-full"
                  onClick={onNextMonth}
                >
                  <svg
                    className="h-6 w-6 text-gray-500 inline-flex"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </button>
              </div>
            </div>

            <div className="flex flex-wrap mb-3 -mx-1">
              {DAYS.map(day => (
                <div
                  style={{
                    width: cellSize,
                  }}
                  className="px-1"
                >
                  <div className="text-gray-800 font-medium text-center text-lg">
                    {day}
                  </div>
                </div>
              ))}
            </div>

            <div className="flex flex-wrap -mx-1">
              {cells.map((cell, i) => {
                const slots =
                  (cell.date.isSameOrAfter(today) &&
                    availability?.[cell.date.format('DD/MM/YY')]) ||
                  []
                const isSelected = cell.date.isSame(selectedDate)
                const hasSlots = !!slots.length
                if (cell.type === 'blank') {
                  return (
                    <div
                      key={i}
                      style={{
                        width: cellSize,
                        height: cellSize,
                      }}
                      className="flex items-center justify-center text-center border p-1 justify-items-center border-transparent text-lg "
                    ></div>
                  )
                }
                let className = 'text-gray-700'
                if (cell.type === 'grey') {
                  className = `${
                    hasSlots
                      ? 'hover:bg-indigo-400 hover:text-white bg-indigo-500 text-white cursor-pointer'
                      : 'text-gray-400'
                  }`
                } else if (isSelected && hasSlots) {
                  className = `hover:bg-indigo-400 hover:text-white bg-indigo-500 text-white cursor-pointer`
                } else {
                  className = `${
                    hasSlots
                      ? 'hover:bg-indigo-400 hover:text-white bg-indigo-500 text-white cursor-pointer'
                      : ''
                  }`
                }
                const key = cell.date.format('DD-MM-YY')
                const isToday = today.format('DD-MM-YY') === key
                return (
                  <div
                    key={key}
                    style={{
                      width: cellSize,
                      height: cellSize,
                    }}
                    className="flex items-center justify-center"
                    data-for={key}
                    data-tip={
                      hasSlots ? `${slots.length} times available` : undefined
                    }
                  >
                    <div
                      onClick={
                        hasSlots
                          ? () => {
                              setDate(cell.date)
                              setHasSelected(true)
                              onSelectDate(cell.date)
                              if (isMobile) {
                                window.scroll(0, 0)
                              }
                            }
                          : undefined
                      }
                      className={`w-3/4 h-3/4 flex px-1 mb-1 items-center justify-items-center text-center text-lg rounded-full leading-loose transition ease-in-out duration-100 ${className}`}
                    >
                      <div className={`w-full relative`}>
                        {cell.date.format('D')}
                        {isToday && (
                          <>
                            <div
                              style={{ bottom: '1px' }}
                              className="w-full absolute justify-center left-0 flex"
                            >
                              <div
                                style={{ width: 6, height: 6 }}
                                className={`rounded-full ${
                                  hasSlots ? 'bg-white' : `bg-indigo-500`
                                }`}
                              />
                            </div>
                          </>
                        )}
                      </div>
                      {hasSlots && <ReactTooltip id={key} place="bottom" />}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          <TimezonePicker
            value={timezone}
            onChange={t => {
              setTimezone(t)
              setTzParam(t, StringParam)
            }}
          />
        </div>

        {!!selectedSlots.length && (
          <>
            {/* desktop */}
            <div
              style={{
                height: 490,
              }}
              className="hidden md:block w-full p-4 h-full"
            >
              <div className="flex flex-col space-y-2 h-full">
                <div className="">
                  <div className="text-2xl flex space-between w-full text-gray-600 font-base">
                    <div className="font-bold pr-2 text-gray-800">
                      {selectedDate?.format('dddd')}{' '}
                      <span className="text-gray-400">-</span>
                    </div>
                    <div>{selectedDate?.format(' MMM Do ')}</div>
                  </div>
                </div>
                <div className="overflow-y-scroll space-y-4">
                  {selectedSlots.map(slot => (
                    <div
                      key={slot.start}
                      onClick={() => {
                        onSelectSlot({
                          ...slot,
                          timezone: timezone || calendar.timezone,
                        })
                      }}
                      className="relative w-full p-6 rounded-lg border-gray-300 border text-center text-xl cursor-pointer hover:bg-gray-100 active:bg-gray-100"
                    >
                      {moment(slot.start)
                        .tz(timezone)
                        .format('HH:mm')}
                      {slot.totalSpots > 1 && (
                        <div className="text-xs text-gray-600 absolute top-1 right-2 underline hover:no-underline px-2">
                          {slot.spotsAvailable} space
                          {slot.spotsAvailable > 1 ? 's' : ''} available
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {/* mobile */}
            {hasSelected && (
              <MobileView
                title={selectedDate?.format('dddd, MMM Do YYYY')}
                isMobileOnly
                onBack={() => {
                  onSelectDate() // removes selected date
                  setHasSelected(false)
                }}
              >
                <NavList className="h-full min-h-full">
                  {selectedSlots.map(slot => (
                    <NavListItem
                      key={slot.start}
                      onClick={() => {
                        onSelectSlot({
                          ...slot,
                          timezone: timezone || calendar.timezone,
                        })
                      }}
                      className="relative"
                    >
                      <div className="text-center">
                        {moment(slot.start)
                          .tz(timezone)
                          .format('HH:mm')}
                      </div>
                      {slot.totalSpots > 1 && (
                        <div className="text-xs text-gray-700 absolute left-1 bottom-1 pb-2 underline hover:no-underline px-2">
                          {slot.spotsAvailable} space
                          {slot.spotsAvailable > 1 ? 's' : ''} available
                        </div>
                      )}
                    </NavListItem>
                  ))}
                </NavList>
              </MobileView>
            )}
          </>
        )}
        {!selectedSlots.length && (
          <div
            className="hidden md:block w-full"
            style={{
              height: '420px',
            }}
          >
            <div className="flex flex-col space-y-2 h-full">
              <div>
                <span className="ml-1 text-2xl text-gray-600 font-normal"></span>
              </div>
              <div className="text-center p-10 text-lg quote">
                👈 Select a date on the left
              </div>
            </div>
          </div>
        )}
      </div>

      <Modal
        icon={<GlobeAltIcon className="w-8 h-8 text-gray-400" />}
        isOpen={sameTimeZoneRequired.isOpen}
        title="Timezone Restricted"
      >
        <p className="mt-4">
          The organiser has restricted booking to the same timezone
          <p className="mt-8">
            Organisers timezone:{' '}
            <span className="text-gray-500">{calendar.timezone}</span>{' '}
          </p>
          <p>
            Your current timezone:{' '}
            <span className="text-gray-500">{timezone}</span>
          </p>
        </p>
      </Modal>
    </div>
  )
}

SlotPicker.defaultProps = {
  placeholder: 'Select date',
  wrapInCard: true,
}
