import { useState, useContext, createContext, useEffect } from 'react'

export const DisclosureContext = createContext()
const useDisclosureP = () => {
  const [isOpen, setOpen] = useState(false)
  return {
    isOpen,
    onOpen: () => {
      setOpen(!isOpen)
    },
    onClose: () => {
      setOpen(false)
    },
  }
}

export const Disclosure = ({ openByDefault, children }) => {
  const disclosure = useDisclosureP(openByDefault)
  return (
    <Disclosure.Provider value={disclosure}>{children}</Disclosure.Provider>
  )
}

export function useDisclosure(openByDefault) {
  let disclosure = useContext(DisclosureContext)
  const _disclosure = useDisclosureP()
  if (!disclosure) disclosure = _disclosure
  useEffect(() => {
    if (openByDefault) disclosure.onOpen()
  }, [])
  return disclosure
}
