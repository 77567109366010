import { useHistory, useParams } from 'react-router'
import { client, useReservation } from '../components/Booking/hooks'
import { RescheduleBooking } from '../components/Booking/RescheduleBooking'
import { BookingNotFound } from '../components/Booking/BookingNotFound'
import moment from 'moment-timezone'
import { CalendarIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import { FullWidthButton } from '../components/Button'

const RE_URL = /\w+:\/\/\S+/g

function linkify(str) {
  let match
  const results = []
  let lastIndex = 0
  while ((match = RE_URL.exec(str))) {
    const link = match[0]
    if (lastIndex !== match.index) {
      const text = str.substring(lastIndex, match.index)
      results.push(<span key={results.length}>{text}</span>)
    }
    results.push(
      <a
        key={results.length}
        href={link}
        className="hover:text-blue-400 text-blue-600 p2-4"
        target="_blank"
      >
        {link}
      </a>
    )
    lastIndex = match.index + link.length
  }
  if (results.length === 0) {
    return str
  }
  if (lastIndex !== str.length) {
    results.push(<span key={results.length}>{str.substring(lastIndex)}</span>)
  }
  return results
}

export default function RescheduleWrapper({ account }) {
  const { reservationId } = useParams()
  const reservation = useReservation(account.id, reservationId)
  if (reservation?.data?.notFound) {
    return <BookingNotFound account={account} />
  }
  if (!reservation.data) return null
  return <Reschedule reservation={reservation} account={account} />
}

export function Reschedule({ account, reservation }) {
  const { reservationId } = useParams()
  const calendar = account.calendars.find(
    c => c.id === reservation?.data?.calendarId
  )
  const _eventType = calendar.eventTypes.find(
    st => st.id === reservation?.data?.calendarEventTypeId
  )
  const [eventType, setEventType] = useState(_eventType)
  const [slot, setSlot] = useState()
  const [reason, setReason] = useState('')
  const history = useHistory()
  return (
    <div className="max-w-4xl mx-auto lg:px-8 flex flex-col">
      <div className="bg-white h-full shadow-lg px-8 mb-10 space-y-4 text-center space-y-3">
        <div className="p-8 space-y-4">
          <CalendarIcon className="w-16 h-16 p-4 m-auto rounded-full bg-green-200 text-green-800" />
          <h1 className="text-3xl tracking-tight font-extrabold text-gray-700 sm:mt-5 lg:mt-6">Reschedule</h1>
          {!slot && (
            <>
              <div className="text-2xl">{reservation?.data?.title}</div>
              <div className="text-lg">
                {moment(reservation?.data?.start).format('dddd Do MMM h:mm a')}{' '}
                - {moment(reservation?.data?.end).format('h:mm a')}{' '}
              </div>
            </>
          )}
        </div>
        {!slot && (
          <RescheduleBooking
            account={account}
            calendar={calendar}
            eventType={eventType}
            onSelectEventType={setEventType}
            onSelectSlot={setSlot}
          />
        )}
        {slot && (
          <>
            <div className="flex space-y-4 flex-col px-20">
              <div className="text-lg">
                <div className="font-semibold">Previous Booking:</div>
                {moment(reservation?.data?.start).format(
                  'dddd Do MMM h:mm a'
                )}{' '}
                - {moment(reservation?.data?.end).format('h:mm a')}
              </div>
              <div className="text-lg">
                <div className="font-semibold">New Booking:</div>
                {moment(slot.start).format('dddd Do MMM h:mm a')} -{' '}
                {moment(slot.end).format('h:mm a')}
              </div>

              <div className="text-lg font-semibold text-left">
                Reason for rescheduling: (Optional)
              </div>
              <textarea
                className="border border-gray-200"
                placeholder="I've had to reschedule because..."
                onChange={e => setReason(e.target.value)}
              >
                {reason}
              </textarea>
              <FullWidthButton
                onClick={async () => {
                  try {
                    const response = await client.post(
                      `/accounts/${account.id}/calendars/${reservation.data?.calendarId}/reservations/${reservationId}/reschedule`,
                      {
                        reason,
                        start: slot.start,
                        end: slot.end,
                      }
                    )
                    history.push(`/${account.slug}/r/${response.data?.id}`)
                  } catch (e) {
                    console.error(e)
                    alert(e?.response?.message || 'An Error Occurred')
                  }
                }}
              >
                Confirm Reschedule
              </FullWidthButton>
            </div>
          </>
        )}
        <div className="flex flex-row justify-between w-full mt-4 border-t-2 border-gray-200">
          <Link
            to={`/${account.slug}/r/${reservationId}/cancel`}
            className="text-red-600 cursor-pointer text-center flex-1 p-2"
          >
            Cancel
          </Link>
        </div>
      </div>
    </div>
  )
}
