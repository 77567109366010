import React from 'react'
import { hydrate } from 'react-dom'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { BlogDataContext } from './contexts'

hydrate(
  <BrowserRouter history={createBrowserHistory()}>
    <BlogDataContext.Provider value={window.__blogData__}>
      <App
        account={window.__account__}
      />
    </BlogDataContext.Provider>
  </BrowserRouter>,
  document.getElementById('root')
)

if (module.hot) {
  module.hot.accept()
}
