import React from 'react'
import Markdown from '../Markdown'

export default function BookingSummary({ account, calendar }) {
  return (
    <>
      <h1
        className={`mt-4 text-3xl tracking-tight font-extrabold text-gray-700 sm:mt-5 lg:mt-6 ${calendar
          ?.bookingPageOptions?.textAlign || 'text-left'}`}
      >
        {calendar.name}
      </h1>
      {!!calendar.description && (
        <div
          className={`markdown ${calendar?.bookingPageOptions?.textAlign ||
            'text-left'}`}
        >
          <Markdown markdown={calendar.description} />
        </div>
      )}
    </>
  )
}
