import React, { useState } from 'react'
import 'tailwindcss/tailwind.css'
import './notion.css'
import {
  Switch,
  Route,
  Router,
  Link,
  Redirect,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import Details from './pages/Details'
import Confirmation from './pages/Confirmation'
import Reschedule from './pages/Reschedule'
import AcceptDecline from './pages/AcceptDecline'
import Cancel from './pages/Cancel'
import BookingPage from './pages/Booking'
import { CalendarCard } from './components/CalendarCard'
import { DetectDeviceProvider } from './hooks/useDetectDevice'
import LandingPage from './landing-page'
import Privacy from './landing-page/Privacy'
import Terms from './landing-page/Terms'
import BlogList from './pages/BlogList'
import BlogPage from './pages/BlogPage'
import CookiePolicy from './landing-page/CookiePolicy'
import { QueryParamProvider } from 'use-query-params'

const queryClient = new QueryClient()

function Routes({ account }) {
  const { pathname } = useLocation()
  return (
    <Switch>
      <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
      <Route path="/privacy" exact>
        <Privacy />
      </Route>
      <Route path="/terms" exact>
        <Terms />
      </Route>
      <Route path="/blog" exact>
        <BlogList />
      </Route>
      <Route path="/blog/:blog_slug">
        <BlogPage />
      </Route>
      <Route path="/cookie-policy" exact>
        <CookiePolicy />
      </Route>
      <Route path="/" exact>
        <LandingPage account={account} />
      </Route>
      {account && (
        <Switch>
          <Route path={`/${account.slug}`} exact>
            <Home account={account} />
          </Route>
          <Route path={`/${account.slug}/r/:reservationId`} exact>
            <Confirmation account={account} />
          </Route>
          <Route path={`/${account.slug}/r/:reservationId/reschedule`} exact>
            <Reschedule account={account} />
          </Route>
          <Route
            path={`/${account.slug}/r/:reservationId/accept-decline`}
            exact
          >
            <AcceptDecline account={account} />
          </Route>
          <Route path={`/${account.slug}/r/:reservationId/cancel`} exact>
            <Cancel account={account} />
          </Route>
          <Route path={`/${account.slug}/:slug`}>
            <Calendar account={account} />
          </Route>
        </Switch>
      )}
    </Switch>
  )
}

function Calendar({ account }) {
  const { slug } = useParams()
  const calendar = account.calendars.find(c => c.slug === slug)
  if (!calendar) {
    return <div>Not Found</div>
  }
  return (
    <>
      <Switch>
        <Route path={`/${account.slug}/${slug}/custom/:start/:end`} exact>
          <Details account={account} calendar={calendar} />
        </Route>
        <Route path={`/${account.slug}/${slug}/:eventType/:start/:end`} exact>
          <Details account={account} calendar={calendar} />
        </Route>
        <Route path={`/${account.slug}/${slug}/:eventType`} exact>
          <BookingPage account={account} calendar={calendar} />
        </Route>
        <Route path={`/${account.slug}/${slug}`} exact>
          <BookingPage account={account} calendar={calendar} />
        </Route>
      </Switch>
      {!account.isPro && (
        <div className="w-full py-10 flex space-y-4 flex-col items-center">
          <a href="/" className="text-gray-500">
            Get your free booking page
          </a>
          <a href="/" className="capitalize text-gray-300">
            powered by cashew scheduling
          </a>
        </div>
      )}
    </>
  )
  return
}

function Home({ account }) {
  const { url } = useRouteMatch()
  if (!account) return <div>Not found</div>
  return (
    <>
      {account?.calendars?.length === 1 && (
        <BookingPage account={account} calendar={account.calendars[0]} />
      )}
      {account?.calendars?.length > 1 && (
        <div className="mt-10 shadow-lg max-w-4xl p-4 mx-auto lg:px-8">
          <h1 className="text-3xl tracking-tight font-extrabold text-gray-700 sm:mt-5 lg:mt-6">
            {account.name}
          </h1>
          <ul
            role="list"
            className="grid mt-10 grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3"
          >
            {account?.calendars?.map(calendar =>
              calendar.type === 'multi' ? (
                <Link key={calendar.id} to={`${url}/${calendar.slug}`}>
                  <CalendarCard calendar={calendar} />
                </Link>
              ) : null
            )}
          </ul>
        </div>
      )}
    </>
  )
}

export default function AppWrapper({ account, detectedDevices }) {
  return (
    <QueryParamProvider ReactRouterRoute={Route}>
      <DetectDeviceProvider detectedDevices={detectedDevices}>
        <QueryClientProvider client={queryClient}>
          <Routes account={account} />
        </QueryClientProvider>
      </DetectDeviceProvider>
    </QueryParamProvider>
  )
}
