import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline'
import { Switch } from '@headlessui/react'
import classNames from './classNames'
import { Picker, PickerItem } from './Picker'
import { passwordStrength } from 'check-password-strength'
import { useMemo, useState } from 'react'
export const Input = ({
  id,
  label,
  name,
  value,
  type,
  onChange,
  autoComplete,
  required,
  helpText,
  optional,
  error,
  ...rest
}) => {
  const [inputType, setInputType] = useState(type)
  const [showPassword, setShowPassword] = useState(false)
  return (
    <div>
      <div className="flex justify-between">
        <label htmlFor={id} className="block text-sm font-medium text-gray-700">
          {label}
        </label>
        {optional && (
          <span className="text-sm text-gray-500" id="email-optional">
            Optional
          </span>
        )}
      </div>

      <div className={error ? 'mt-1 relative rounded-md shadow-sm' : 'mt-1 '}>
        <input
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          type={inputType}
          autoComplete={autoComplete}
          required={required}
          aria-invalid={!!error}
          className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm ${
            error
              ? 'block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md'
              : ''
          }`}
          {...rest}
        />
        {error && type !== 'password' && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
        {type === 'password' && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
            {!showPassword && (
              <EyeIcon
                className="h-5 w-5 text-gray-500 cursor-pointer"
                aria-hidden="true"
                onClick={() => {
                  setInputType('text')
                  setShowPassword(true)
                }}
              />
            )}
            {showPassword && (
              <EyeOffIcon
                className="h-5 w-5 text-gray-500 cursor-pointer"
                aria-hidden="true"
                onClick={() => {
                  setInputType('password')
                  setShowPassword(false)
                }}
              />
            )}
          </div>
        )}
      </div>
      {helpText && !error && (
        <p className="mt-2 text-sm text-gray-500" id="email-description">
          {helpText}
        </p>
      )}
      {error && (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {error}
        </p>
      )}
    </div>
  )
}

export const FieldWrapper = ({
  component: Component,
  id,
  label,
  name,
  value,
  required,
  helpText,
  optional,
  error,
  ...rest
}) => {
  return (
    <div>
      <div className="flex justify-between">
        <label htmlFor={id} className="block text-sm font-medium text-gray-700">
          {label}
        </label>
        {optional && (
          <span className="text-sm text-gray-500" id="email-optional">
            Optional
          </span>
        )}
      </div>

      <div className={error ? 'mt-1 relative rounded-md shadow-sm' : 'mt-1 '}>
        <Component value={value} {...rest} />
        {error && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      {helpText && !error && (
        <p className="mt-2 text-sm text-gray-500" id="email-description">
          {helpText}
        </p>
      )}
      {error && (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {error}
        </p>
      )}
    </div>
  )
}

export const BasicCheckbox = ({ id, name, label, checked, onChange }) => (
  <div className="flex items-center">
    <input
      id={id}
      name={name}
      type="checkbox"
      checked={checked}
      onChange={onChange}
      className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded cursor-pointer"
    />
    <label
      htmlFor={id}
      className="ml-2 block text-sm text-gray-900 cursor-pointer"
    >
      {label}
    </label>
  </div>
)

export const Link = ({ as: As, ...props }) => {
  const classes = 'font-medium text-blue-600 hover:text-blue-500 cursor-pointer'
  if (As) {
    return <As className={classes} {...props} />
  }
  // eslint-disable-next-line jsx-a11y/anchor-has-content
  return <a className={classes} {...props} />
}

export const Seperator = ({ children }) => (
  <div className="relative">
    <div className="absolute inset-0 flex items-center">
      <div className="w-full border-t border-gray-300" />
    </div>
    <div className="relative flex justify-center text-sm">
      <span className="lg:px-4 bg-white text-gray-500">{children}</span>
    </div>
  </div>
)

export const PrefixInput = ({
  value,
  name,
  id,
  onChange,
  label,
  optional,
  error,
  placeholder,
  prefix,
  ...rest
}) => (
  <div>
    <label htmlFor={name} className="block text-sm font-medium text-gray-700">
      {label}
    </label>
    <div className="mt-1 flex rounded-md shadow-sm">
      <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
        {prefix}
      </span>
      <input
        type="text"
        name={name}
        value={value}
        onChange={onChange}
        id={id}
        className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300"
        placeholder={placeholder}
        {...rest}
      />
    </div>
  </div>
)

export const SuffixInput = ({
  value,
  name,
  id,
  onChange,
  label,
  optional,
  error,
  placeholder,
  suffix,
  ...rest
}) => (
  <div>
    <label htmlFor={name} className="block text-sm font-medium text-gray-700">
      {label}
    </label>
    <div className="mt-1 flex rounded-md shadow-sm">
      <input
        type="text"
        name={name}
        value={value}
        onChange={onChange}
        id={id}
        className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-l-md focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300"
        placeholder={placeholder}
        {...rest}
      />
      <span className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
        {suffix}
      </span>
    </div>
    {error && (
      <p className="mt-2 text-sm text-red-600" id="email-error">
        {error}
      </p>
    )}
  </div>
)

export const Select = ({
  value,
  name,
  id,
  label,
  error,
  options,
  optional,
  helpText,
  ...rest
}) => (
  <div>
    <div className="flex justify-between">
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      {optional && <span className="text-sm text-gray-500">Optional</span>}
    </div>
    <div className={error ? 'mt-1 relative rounded-md shadow-sm' : 'mt-1 '}>
      <Picker value={value} {...rest}>
        {options.map(({ value: v, label: l }) => (
          <PickerItem value={v}>{l}</PickerItem>
        ))}
      </Picker>
    </div>
    {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
    {helpText && !error && (
      <p className="mt-2 text-sm text-gray-500">{helpText}</p>
    )}
  </div>
)

export const required = value => {
  return value ? undefined : 'Required'
}
export const mustBeNumber = value =>
  isNaN(value) ? 'Must be a number' : undefined
export const minValue = (min, errorMessage) => value =>
  isNaN(value) || value >= min
    ? undefined
    : errorMessage || `Should be greater than or equal to ${min}`
export const maxValue = (max, errorMessage) => value =>
  isNaN(value) || value >= max
    ? errorMessage || `Should be less than or equal to ${max}`
    : undefined
export const minLength = (min, errorMessage) => value =>
  value?.length >= min ? undefined : errorMessage || `${min} or more characters`
export const maxLength = (max, errorMessage) => value =>
  value?.length >= max
    ? errorMessage || `less than ${max} characters`
    : undefined
export const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined)
export const composeValidatorsOptional = (...validators) => value =>
  validators.reduce(
    (error, validator, i) =>
      error || value === undefined || value === ''
        ? undefined
        : validator(value),
    undefined
  )

export const PasswordStrength = ({ value }) => {
  const strength = useMemo(() => {
    if (!value) return ''
    return passwordStrength(value)
  }, [value])
  return (
    <>
      {strength?.value === 'Too weak' && (
        <span className="align-right w-full text-red-800">Too weak</span>
      )}
      {strength?.value === 'Weak' && (
        <span className="align-right w-full text-red-600">Weak</span>
      )}
      {strength?.value === 'Medium' && (
        <span className="align-right w-full  text-green-500">Strong</span>
      )}
      {strength?.value === 'Strong' && (
        <span className="align-right w-full  text-green-800">Strong</span>
      )}
    </>
  )
}
export const validatePasswordStrength = value => {
  const strength = passwordStrength(value)
  if (['Strong', 'Medium'].includes(strength?.value)) {
    return ''
  }

  return 'Password not strong enough'
}

const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const validEmail = email =>
  !!email?.match(EMAIL_REGEX) ? undefined : 'Invalid Email'

export function Toggle({
  id,
  textLeft,
  label,
  value,
  onChange,
  helpText,
  error,
}) {
  return (
    <div>
      <div className="flex items-center">
        {textLeft && (
          <label
            htmlFor={id}
            className="mr-2 block text-sm text-gray-900 cursor-pointer"
          >
            {label}
          </label>
        )}
        <Switch
          checked={value}
          onChange={onChange}
          className={classNames(
            value ? 'bg-blue-600' : 'bg-gray-200',
            'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
          )}
        >
          <span className="sr-only">{label}</span>
          <span
            aria-hidden="true"
            className={classNames(
              value ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
            )}
          />
        </Switch>
        {!textLeft && (
          <label
            htmlFor={id}
            className="ml-2 block text-sm text-gray-900 cursor-pointer"
          >
            {label}
          </label>
        )}
      </div>
      {helpText && !error && (
        <p className="mt-2 text-sm text-gray-500">{helpText}</p>
      )}
    </div>
  )
}
