import React from 'react'
import { ClockIcon, CalendarIcon } from '@heroicons/react/solid'
import moment from 'moment-timezone'

export function BookingDetails({ reservation }) {
  return (
    <>
      <div className="flex py-2">
        <ClockIcon className="w-6 h-6 text-gray-800" />
        <div className="pl-3 text-base self-center">
          {moment(reservation.start).format('h:mm a')} -{' '}
          {moment(reservation.end).format('h:mm a')}
        </div>
      </div>
      <div className="flex py-2">
        <CalendarIcon className="w-6 h-6 text-gray-800" />
        <div className="pl-3 text-base self-center">
          {moment(reservation.start).format('dddd, MMMM Do YYYY')}
        </div>
      </div>
    </>
  )
}
