import { CheckCircleIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'

export function BookingNotFound({ account }) {
  return (
    <div className="max-w-2xl mx-auto p-8 flex flex-col">
      <div className="bg-white shadow-lg h-full space-y-4 text-center space-y-3">
        <div className="p-8">
          <CheckCircleIcon className="w-16 h-16 m-auto text-green-500" />
          <h1 className="text-3xl py-2 text-warmGray-800">Not Found</h1>
          <div className="text-2xl">
            The booking you are looking for no longer exists
          </div>
        </div>
        <div className="flex flex-row justify-between w-full mt-4 border-t-2 border-gray-200">
          <Link
            to={`/${account.slug}`}
            className="text-blue-600 p-2 border-r-2 flex-1 cursor-pointer text-center border-gray-200"
          >
            Create a new booking?
          </Link>
        </div>
      </div>
    </div>
  )
}
