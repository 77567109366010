import React, { useMemo } from 'react'

export const PickerBlank = ({ children }) => (
  <div className="self-center">{children}</div>
)

const Select = ({ children, value, onChange, ...rest }) => (
  <select
    value={value}
    onChange={onChange}
    className="mt-1 border-2 block w-full px-2 py-2 text-base pointer-events border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
    {...rest}
  >
    {children}
  </select>
)

export function Picker({ children, value, onChange }) {
  return (
    <Select value={value} onChange={onChange}>
      {React.Children.map(children, child => {
        if (child.type === PickerItem) {
          return child
        }
        if (process.env.NODE_ENV !== 'production') {
          throw Error('Unknown PickerItem in Picker Component')
        }
        return null
      })}
    </Select>
  )
}
const MultiPickerWrapper = ({ children }) => (
  <div className="flex flex-row">{children}</div>
)
export function MultiPicker({ children, value, onChange }) {
  function handleChange(i, e) {
    const newValue = [...value]
    const changeValue = e.target ? e.target.value : e
    newValue[i] = changeValue
    onChange(newValue)
  }
  const pickerIndexes = useMemo(() => {
    const indexes = {}
    let count = 0
    React.Children.forEach(children, (p, i) => {
      if (p.type === Picker) {
        indexes[i] = count
        count = count + 1
      }
    })
    return indexes
  }, [children])
  return (
    <MultiPickerWrapper>
      {React.Children.map(children, (picker, i) => {
        const isPicker = picker.type === Picker
        const isBlank = picker.type === PickerBlank
        if (isPicker) {
          const index = pickerIndexes[i]
          return (
            <div>
              {React.cloneElement(picker, {
                onChange: e => handleChange(index, e),
              })}
            </div>
          )
        }
        if (isBlank) {
          return picker
        }
        if (process.env.NODE_ENV !== 'production') {
          throw Error('Unknown Picker Child Component in MultiPicker Component')
        }
        return null
      })}
    </MultiPickerWrapper>
  )
}

const Option = ({ value, children }) => (
  <option className="bg-white" value={value}>
    {children}
  </option>
)
export function PickerItem({ children, value }) {
  return <Option value={value}>{children}</Option>
}
