import { ChevronRightIcon } from '@heroicons/react/solid'
export function NavList({ className, children }) {
  return (
    <div className={`min-h-full pb-6 grid grid-cols-1 gap-4 mx-8 ${className}`}>
      {children}
    </div>
  )
}
export function NavListItem({ onClick, className, hideChevron, children }) {
  return (
    <div
      className={`p-4 rounded-lg cursor-pointer border border-gray-300 bg-white md:px-6 md:py-5 hover:bg-gray-100 active:bg-gray-100 flex items-center self-start space-x-3 ${className}`}
      onClick={onClick}
    >
      <div className="w-full">{children}</div>
      {!hideChevron && (
        <ChevronRightIcon
          className="h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
      )}
    </div>
  )
}
NavListItem.defaultProps = {
  hideChevron: false,
}
