import Markdown from "./Markdown"
export function CalendarCard({ calendar }) {
  return (
    <li className="col-span-1 min-h-100 bg-white rounded-md border border-gray-300 divide-y divide-gray-200">
      <div className="w-full flex items-center justify-between p-6 space-x-6">
        <div className="flex-1 truncate">
          <div className="flex items-center space-x-3">
            <h3 className="text-gray-900 text-sm font-medium truncate">
              {calendar.name}
            </h3>
          </div>
          <p className="mt-1 text-gray-500 text-sm truncate">
            <Markdown markdown={calendar.description} />
          </p>
        </div>
        {/* <img className="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0" src={person.imageUrl} alt="" /> */}
      </div>
    </li>
  )
}
