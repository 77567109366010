import { ChevronLeftIcon } from '@heroicons/react/solid'

export function MobileView({ children, onBack, title, isMobileOnly }) {
  return (
    <>
      <div className="md:hidden">
        <div className="w-full relative p-4 items-center flex border-b-2 border-gray-300">
          <ChevronLeftIcon
            onClick={onBack}
            className="w-8 h-8 cursor-pointer text-gray-900"
          />
          <div className="m-auto text-2xl font-semibold text-gray-900">
            {title}
          </div>
        </div>
        <div className="h-full pt-4">{children}</div>
      </div>
      {!isMobileOnly && <div className="hidden md:block">{children}</div>}
    </>
  )
}
