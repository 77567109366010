import { useMemo, useContext, createContext } from 'react'
import * as deviceDetect from 'react-device-detect'
const UserAgentContext = createContext()

export const DetectDeviceProvider = ({ detectedDevices, children }) => {
  return (
    <UserAgentContext.Provider value={detectedDevices}>
      {children}
    </UserAgentContext.Provider>
  )
}

export const useDetectDevice = () => {
  const detected = useContext(UserAgentContext)
  return useMemo(() => {
    console.log('detected', detected)
    if (!detected) {
      const userAgent =
        typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent
      const isAndroid = Boolean(userAgent.match(/Android/i))
      const isIos = Boolean(userAgent.match(/iPhone|iPad|iPod/i))
      const isSSR = Boolean(userAgent.match(/SSR/i))
      return {
        isMobile: deviceDetect.isMobile,
        isDesktop: !deviceDetect.isMobile && !deviceDetect.isTablet,
        isAndroid,
        isIos,
        isSSR,
      }
    }
    const { isMobile, isDesktop, isAndroid, isIos } = detected
    return {
      isMobile,
      isDesktop,
      isAndroid,
      isIos,
    }
  }, [])
}
