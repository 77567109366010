import { useState, useContext, createContext, useEffect } from 'react'
import { useQuery } from 'react-query'
import axios from 'axios'
import moment from 'moment'
import qs from 'qs'
import _ from 'lodash/fp'

export const client = axios.create({
  baseURL: process.env.RAZZLE_API_URL || 'https://api.cashew.events', // env vars are fucked in build
})

function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay] // Only re-call effect if value or delay changes
  )

  return debouncedValue
}

export function useAvailability(account, calendar, eventType, slotSize) {
  const [start, setStart] = useState(
    moment()
      .tz(calendar.timezone)
      .startOf('month')
  )
  const [end, setEnd] = useState(
    moment()
      .tz(calendar.timezone)
      .endOf('month')
  )
  const _start = useDebounce(start, 500)
  const _end = useDebounce(end, 500)

  const { isLoading, error, data, refetch } = useQuery(
    [_start, _end, eventType, calendar, slotSize],
    async () => {
      const query = qs.stringify({
        from: _start.tz(calendar.timezone).format(),
        to: _end.tz(calendar.timezone).format(),
        slotSize,
        ...(eventType ? { calendarEventTypeId: eventType.id } : {}),
      })
      const { data } = await client.get(
        `/accounts/${account.id}/calendars/${calendar.id}/availability?${query}`
      )
      console.log('start after fetch', _start.format('MM/YY'))

      const grouped = _.groupBy(slot =>
        moment(slot.start)
          .tz(moment.tz.guess())
          .format('DD/MM/YY')
      )(data)
      return grouped
    },
    { refetchOnWindowFocus: false }
  )

  return {
    availability: data,
    setMonth: m => {
      console.log(m.format('MM/YY'))
      setStart(m)
      setEnd(
        m
          .clone()
          .add(1, 'month')
          .endOf('month')
      )
    },
    refetch,
    isLoading,
    month: start,
  }
}

export function useCalendar() {
  return {}
}

export function useRoundTheOutside(ref, onRoundTheOutside) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onRoundTheOutside()
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}

export const DisclosureContext = createContext()
const useDisclosureP = () => {
  const [isOpen, setOpen] = useState(false)
  return {
    isOpen,
    onOpen: () => {
      setOpen(!isOpen)
    },
    onClose: () => {
      setOpen(false)
    },
  }
}

export const useCustomFields = accountId => {
  const res = useQuery(`custom-fields-${accountId}`, async () => {
    const result = await client.get(`/accounts/${accountId}/custom-fields/`)
    return result.data
  })
  return res
}

export const useReservation = (accountId, reservationId) => {
  const res = useQuery(
    `reservation-${accountId}-${reservationId}`,
    async () => {
      try {
        const result = await client.get(
          `/accounts/${accountId}/reservations/${reservationId}/customer`
        )
        return result.data
      } catch (e) {
        if (e?.response?.status === 404) {
          return { notFound: true }
        }
        throw e
      }
    }
  )
  return res
}
