import { Fragment, useState } from 'react'
import { Dialog } from '@headlessui/react'
import { Portal } from 'react-portal'
import { XIcon } from '@heroicons/react/outline'

function ModalC({
  onClose,
  isOpen,
  title,
  actions,
  type,
  children,
  icon,
  iconClassName,
  iconBgColor
}) {
  return (
    <Portal>
      <div
        className="absolute md:fixed inset-0 overflow-y-auto"
      >
        <div className="flex md:items-start lg:min-h-screen pt-4 px-4 pb-20 text-center justify-center">
          <div
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            onClick={onClose}
          />
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <div className="absolute h-full overflow-y-auto md:h-auto md:overflow-visible w-full inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:align-middle md:pb-30 sm:max-w-lg sm:w-full sm:p-6">
            <div className="overflow-y-scroll">
              {!!onClose && (
                <div className="absolute cursor-pointer" onClick={onClose}>
                  <XIcon className="text-gray-800 w-6 h-6" />
                </div>
              )}
              <div
                className={`mx-auto flex items-center justify-center h-12 w-12 rounded-full ${iconBgColor}`}
              >
                {icon}
              </div>
              <div className="mt-3 text-center sm:mt-5 overflow-y-scroll">
                <h3 className="text-2xl tracking-tight leading-6 font-medium text-gray-900">
                  {title}
                </h3>
                <div className="mt-1 h-full">{children}</div>
              </div>
              <div className="mt-5 sm:mt-6">{actions}</div>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  )
}
ModalC.defaultProps = {
  type: 'success',
}
export function Modal({ isOpen, ...rest }) {
  return isOpen ? <ModalC isOpen={isOpen} {...rest} /> : null
}
