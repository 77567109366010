import BookingSummary from '../components/Booking/BookingSummary'
import { Form, Field } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import {
  getFinalFormValidator,
  isValidRegEx,
  getCustomFieldInputType,
} from '../fns-duplicated'
import {
  composeValidators,
  required,
  validEmail,
  Input,
  minLength,
  Select,
} from '../components/Form'
import { FullWidthButton } from '../components/Button'
import { useHistory, useParams } from 'react-router'
import {
  CalendarIcon,
  ChevronDownIcon,
  ClockIcon,
  GlobeAltIcon,
  LocationMarkerIcon,
} from '@heroicons/react/solid'
import moment from 'moment-timezone'
import { useCustomFields, client } from '../components/Booking/hooks'
import { toast } from 'react-toastify'
import { useState } from 'react'
import { useDisclosure } from '../hooks/disclosure'
import { Modal } from '../components/Modal'
import { Pill, PillDropdown } from '../components/Pill'
import { useQueryParam, StringParam } from 'use-query-params';

export default function Details({ account, calendar }) {
  const { eventType: eventTypeParam, start, end } = useParams()
  const { data: customFields, refetch: refetchCustomFields } = useCustomFields(
    account.id
  )
  const [tz, setTzParam] = useQueryParam('tz', StringParam);
  const [timezone, setTimezone] = useState(tz || moment.tz.guess())
  const eventType = calendar.eventTypes.find(st => st.slug === eventTypeParam)
  const [eventLocation, setLocation] = useState(
    eventType.location?.length ? eventType.location[0] : ''
  )
  const history = useHistory()
  const notAvailable = useDisclosure()
  const timezoneModal = useDisclosure();
  return (
    <div className="max-w-2xl mx-auto lg:px-8 flex flex-col">
      <div className="bg-white h-full py-4 px-6 md:px-10 space-y-5 drop-shadow-md shadow-lg">
        <div
          className={`mt-4 text-3xl tracking-tight font-extrabold text-gray-700 sm:mt-5 lg:mt-6`}
        >
          {calendar.name}&nbsp;
          {eventType?.title.length > 0 ? `– ${eventType.title}` : ''}
        </div>
        <h1 className="text-l font-semibold text-gray-600">
          Confirm {calendar?.strings?.booking || 'Booking'}
        </h1>
        <div className="flex md:flex-row flex-col">
            <div className="flex md:justify-center">
              <ClockIcon className="w-6 h-6 text-gray-600" />
              <div className="pl-3 text-base text-warmGray-600 self-center">
                {moment(start).tz(timezone).format('h:mm a')} - {moment(end).tz(timezone).format('h:mm a z')}
              </div>
            </div>
            {timezone !== calendar.timezone ? <div className="md:pl-4 pt-2 flex md:justify-center">
              <a href="#" onClick={timezoneModal.onOpen} className="cursor-pointer underline hover:no-underline text-sm text-warmGray-400 self-center">
                host timezone {calendar.timezone}
              </a>
            </div>: null}          
        </div>

        <div className="flex">
          <CalendarIcon className="w-6 h-6 text-gray-600" />
          <div className="pl-3 text-base text-warmGray-600 self-center">
            {moment(start).tz(timezone).format('dddd, MMMM Do YYYY')}
          </div>
        </div>

        <div className="flex items-center">
          <LocationMarkerIcon className="w-6 h-6 text-gray-600 mr-3" />
          {eventType?.location?.length === 1 ||
          eventType?.location?.length === 0 ? (
            <div className="text-base capitalize text-warmGray-600 self-center">
              {eventLocation?.type || 'Unspecified'}
            </div>
          ) : (
            <PillDropdown
              icon={
                <>
                  <LocationMarkerIcon
                    className="h-12 w-12 text-gray-700"
                    aria-hidden="true"
                  />
                </>
              }
              options={eventType?.location.map(({ id, type }) => ({
                value: id,
                name: type,
              }))}
              onChange={({ value }) =>
                setLocation(eventType.location.find(evt => evt.id === value))
              }
            >
              {eventLocation?.type}
            </PillDropdown>
          )}
        </div>
        <Form
          onSubmit={async ({ firstName, lastName, email, fields }) => {
            try {
              const { data: customer } = await client.post(`/customers/guest`, {
                firstName,
                lastName,
                email,
                accountId: account.id,
                customFields: fields,
              })

              const { data } = await client.post(
                `/accounts/${account.id}/calendars/${calendar.id}/customer-reserve`,
                {
                  calendarEventTypeId: eventType && eventType.id,
                  customerId: customer.id,
                  start,
                  end,
                  //eventLocation //TODO:
                }
              )
              history.push(`/${account.slug}/r/${data.id}`)
            } catch (e) {
              console.error(e)
              if (e?.response?.data?.message) {
                if (e.response.data.message === 'Slot no longer available') {
                  notAvailable.onOpen()
                  return
                }
                return { [FORM_ERROR]: e.response.data.message }
              }
            }
          }}
          render={({ handleSubmit, submitting, submitError }) => (
            <>
              {submitError && (
                <div className="text-lg text-red-500 text-center">
                  {submitError}
                </div>
              )}
              <Field
                name="firstName"
                validate={composeValidators(required, minLength(2))}
                render={({ input, meta }) => (
                  <>
                    <Input
                      {...input}
                      autoFocus
                      label="First Name"
                      placeholder="First Name"
                      error={meta.touched && meta.error}
                    />
                  </>
                )}
              />
              <Field
                name="lastName"
                validate={composeValidators(required, minLength(2))}
                render={({ input, meta }) => (
                  <>
                    <Input
                      {...input}
                      label="Last Name"
                      placeholder="Last Name"
                      error={meta.touched && meta.error}
                    />
                  </>
                )}
              />
              <Field
                name="email"
                validate={composeValidators(required, validEmail)}
                render={({ input, meta }) => (
                  <>
                    <Input
                      label="Email"
                      {...input}
                      placeholder="Email"
                      error={meta.touched && meta.error}
                    />
                  </>
                )}
              />
              {customFields?.map(customField => {
                return (
                  <Field
                    key={customField.id}
                    name={`fields.${customField.fieldName}`}
                    validate={getFinalFormValidator(customField)}
                    render={({ input, meta }) => {
                      if (['select-single'].includes(customField.fieldType)) {
                        return (
                          <Select
                            label={customField.name}
                            value={input.value}
                            placeholder={customField.name}
                            // helpText={customField.description}
                            optional={!customField.required}
                            onChange={e => {
                              input.onChange(e.target.value)
                            }}
                            options={[
                              { value: '', label: 'Select' },
                              ...customField.options.map(({ key, value }) => ({
                                value,
                                label: key,
                              })),
                            ]}
                            helpText={customField.description}
                            error={meta?.touched && meta.error}
                          />
                        )
                      }
                      return (
                        <Input
                          variant="flushed"
                          label={customField.name}
                          placeholder={customField.name}
                          // helpText={customField.description}
                          optional={!customField.required}
                          type={getCustomFieldInputType(customField)}
                          {...input}
                          helpText={customField.description}
                          error={meta?.touched && meta.error}
                        />
                      )
                    }}
                  />
                )
              })}
              <FullWidthButton onClick={handleSubmit}>
                {calendar.acceptDeclineEnabled && <>Request</>}
                {!calendar.acceptDeclineEnabled && <>Book</>}
              </FullWidthButton>
              {calendar.acceptDeclineEnabled && <p className="text-sm text-center text-gray-400">Your request will be sent for approval</p>}
            </>
          )}
        />
      </div>
      <Modal
        isOpen={notAvailable.isOpen}
        iconBgType="error"
        icon={<CalendarIcon className="w-8 h-8 text-red-400" />}
        onClose={notAvailable.onClose}
        title="Slot no longer available"
        actions={
          <>
            <FullWidthButton
              onClick={() => {
                notAvailable.onClose()
                history.push(
                  `/${account.slug}/${calendar.slug}/${
                    eventTypeParam === undefined ? 'custom' : eventTypeParam
                  }`
                )
              }}
            >
              Find another slot
            </FullWidthButton>
          </>
        }
      ></Modal>
      <Modal
        isOpen={timezoneModal.isOpen}
        iconBgType="error"
        icon={<GlobeAltIcon className="w-8 h-8 text-gray-400" />}
        onClose={timezoneModal.onClose}
        title="Host timezone"
        actions={
          <>
            <FullWidthButton
              onClick={() => {
                timezoneModal.onClose()
              }}
            >
              Continue
            </FullWidthButton>
          </>
        }
      >

        <p className="text-left py-6">
          It appears your {moment.tz.guess() !== timezone ? <>selected time zone <span className="text-gray-500">{timezone}</span></> : <>detected timezone <span className="text-gray-500">{timezone}</span></>} and the hosts time zone <span className="text-gray-500">{calendar.timezone}</span> differ
          {['zoom', 'hangouts', 'meet'].includes(eventLocation?.type) && <div className="text-center pt-6 text-lg">The event will be held online using {eventLocation?.type} </div>}
        </p>
      </Modal>
      {/* possibly add more info here on how the event will go ahead in different timezones. */}
    </div>
  )
}
