import { useParams } from 'react-router'
import { useReservation } from '../components/Booking/hooks'
import moment from 'moment-timezone'
import { CheckCircleIcon, ClockIcon,CalendarIcon, PencilIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'
import { BookingNotFound } from '../components/Booking/BookingNotFound'
import Markdown from '../components/Markdown'

export default function Confirmation({ account }) {
  const { reservationId } = useParams()
  const reservation = useReservation(account.id, reservationId)
  if (reservation?.isLoading) return null
  if (reservation?.data?.notFound) {
    return <BookingNotFound account={account} />
  }
  const calendar = account.calendars.find(cal => cal.id === reservation.data?.calendarId);
  if (!calendar) return null
  return (
    <div className="max-w-2xl mx-auto p-8 flex flex-col">
      <div className="bg-white shadow-lg h-full space-y-4 space-y-3">
        <div className="p-8">
          <CheckCircleIcon className="w-16 h-16 m-auto text-green-500" />
          {!reservation.data?.userAccepted && moment().isBefore(reservation?.data?.end) && <><h1 className="text-3xl tracking-tight font-extrabold text-gray-700 sm:mt-5 lg:mt-6 text-center">
            Requested
          </h1><p className="text-center text-gray-500 py-2">You will recieve an email when the request is accepted</p></>}
          {calendar.acceptDeclineEnabled && reservation.data?.userAccepted && moment().isBefore(reservation?.data?.end) && <h1 className="text-3xl tracking-tight font-extrabold text-gray-700 sm:mt-5 lg:mt-6 text-center">
            Accepted!
          </h1>}
          {!calendar.acceptDeclineEnabled && moment().isBefore(reservation?.data?.end) && <h1 className="text-3xl tracking-tight font-extrabold text-gray-700 sm:mt-5 lg:mt-6 text-center">
            Confirmed!
          </h1>}
          {moment().isAfter(reservation?.data?.end) && <h1 className="text-3xl tracking-tight font-extrabold text-gray-700 sm:mt-5 lg:mt-6 text-center">
            Complete
          </h1>}
          <div className="text-1xl tracking-tight font-bold pt-10">{reservation?.data?.title}</div>
          <div className="text-left">
            <div className="flex py-2">
              <ClockIcon className="w-6 h-6 text-gray-800" />
              <div className="pl-3 text-base self-center">
                {moment(reservation.data?.start).format('h:mm a')} - {moment(reservation.data?.end).format('h:mm a')}
              </div>
            </div>
            <div className="flex py-2">
              <CalendarIcon className="w-6 h-6 text-gray-800" />
              <div className="pl-3 text-base self-center">
                {moment(reservation.data?.start).format('dddd, MMMM Do YYYY')}
              </div>
            </div>
            {reservation?.data?.description && (
              <>
                <div className="flex py-2">
                  <PencilIcon className="w-6 h-6 text-gray-800" />
                  <div className="pl-3 text-base self-center">Description:</div>
                </div>
                <p className="text-md border-l-4 border-l-gray-400 bg-gray-100 p-4">
                  <Markdown markdown={reservation.data.description} />
                </p>
              </>
            )}

          </div>
        </div>
        {moment().isBefore(reservation?.data.start) && <div className="flex flex-row justify-between w-full mt-4 border-t-2 border-gray-200">
          <Link
            to={`/${account.slug}/r/${reservationId}/reschedule`}
            className="text-blue-600 p-2 border-r-2 flex-1 cursor-pointer text-center border-gray-200"
          >
            Reschedule
          </Link>
          <Link
            to={`/${account.slug}/r/${reservationId}/cancel`}
            className="text-red-600 cursor-pointer text-center flex-1 p-2"
          >
            Cancel
          </Link>
        </div>}
      </div>
    </div>
  )
}
