import { useEffect, useState } from 'react'
/*

  Theme.js set via context.


  Routing via memory router - esp for embed 
  https://reactrouter.com/web/api/MemoryRouter
*/

import SlotPicker from './SlotPicker'
import Details from './Details'
import Confirmation from './Confirmation'
import { useAvailability } from './hooks'
import BookingSummary from './BookingSummary'
import { useHistory, useParams } from 'react-router'
import { ClockIcon } from '@heroicons/react/solid'
import humanizeDuration from 'humanize-duration'
import { Modal } from '../Modal'
import { Pill } from '../Pill'
import { NavList, NavListItem } from '../List'
import { useDisclosure } from '../../hooks/disclosure'
function EventTypes({ eventTypes, onClick }) {
  return (
    <NavList>
      {eventTypes.map(st => {
        return (
          <NavListItem onClick={() => onClick(st)}>
            <div className="w-full text-center">
              {st.title} {humanizeDuration(st.slotSize * 60000)}
            </div>
          </NavListItem>
        )
      })}
    </NavList>
  )
}
export function RescheduleBooking({
  account,
  calendar,
  onSelectSlot,
  eventType,
  onSelectEventType,
}) {
  const { availability, month, setMonth, refresh } = useAvailability(
    account,
    calendar,
    eventType
  )
  const { isOpen, onClose, onOpen } = useDisclosure()

  if (calendar.eventTypes.length > 1 && !eventType) {
    return (
      <div className="mt-3 flex-row space-y-6">
        <EventTypes
          eventTypes={calendar.eventTypes}
          onClick={st => {
            onSelectEventType(st)
          }}
        />
      </div>
    )
  }
  return (
    <div className="mt-3 flex flex-col space-y-6">
      <Pill
        className={`m-4 flex space-x-2 self-start
          ${calendar.eventTypes.length > 1 ? 'cursor-pointer' : ''}
        }`}
        onClick={calendar.eventTypes.length > 1 ? onOpen : undefined}
      >
        <ClockIcon className="h-5 w-5 text-gray-800" aria-hidden="true" />
        <div>
          {humanizeDuration(eventType?.slotSize * 60000 || 60 * 60000)}{' '}
        </div>
      </Pill>
      <SlotPicker
        wrapInCard={false}
        calendar={calendar}
        availability={availability}
        onChangeMonth={m => setMonth(m)}
        month={month}
        onSelectSlot={slot => {
          onSelectSlot(slot)
        }}
        onSelectDate={d => {
          // if (d && isMobile) {
          //   setHideHeader(true)
          // } else {
          //   setHideHeader(false)
          // }
        }}
      />
      <Modal
        title="Choose Event Type"
        isOpen={isOpen}
        onClose={onClose}
        icon={
          <>
            <ClockIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
          </>
        }
      >
        <div className="pt-4">
          {calendar.eventTypes.length > 1 && (
            <EventTypes
              eventTypes={calendar.eventTypes}
              onClick={st => {
                onSelectEventType(st)
                onClose()
              }}
            />
          )}
        </div>
      </Modal>
    </div>
  )
}
