import React,{useState} from 'react'
import TimezoneSelect from 'react-timezone-select'
import {Pill} from './Pill'
import { GlobeAltIcon, ChevronDownIcon } from '@heroicons/react/solid';
export function TimezonePicker({onChange, value}) {
  const [open, setOpen] = useState(false);
  if (open) {
    return <div className="flex min-w-md flex-start">
      <TimezoneSelect
        className="w-full"
        value={value}
        menuIsOpen={open}
        onChange={async ({ value: newValue }) => {
          onChange(newValue)
          setOpen(false);
        }}
      />
    </div>
  } 
  if (!open) {
    return <div className="flex flex-start"><Pill
      onClick={() => setOpen(true)}
    >
      <GlobeAltIcon className="mr-1 w-4 h-4 text-gray-700"/>
      {value} <ChevronDownIcon className="w-5 h-5 text-gray-700"/>
    </Pill>
    </div>

  }
}