const FIELD_TYPES = [
  'string',
  'number',
  'float',
  'email',
  'select-single',
  'select-multi',
]
const customFieldValidators = {
  string: s => typeof s === 'string',
  number: n => !isNaN(n) && n % 1 === 0,
  float: n => !isNaN(n) && n % 1 !== 0,
  email: s => {
    if (!s) return 'Invalid Email'
    return !!s.match(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  },
  link: s => {
    if (!s) return 'Invalid Link'
    return !!s.match(
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
    )
  },
  regex: (s, customField) => !!s.match(customField.regex),
  'select-single': (value, customField) =>
    customField.options.some(option => option.value === value),
  'select-multi': (value, customField) =>
    customField.options.some(option => option.value === value),
}

const validationTemporarilyDisabled = ['select-single', 'select-multi']

const fallback = () => true
const getFinalFormValidator = customField => {
  return v => {
    if (validationTemporarilyDisabled.includes(customField.fieldType)) return ''
    const validator = customFieldValidators[customField.fieldType] || fallback
    if (!v && !`${v}`.length && customField.required) {
      return 'Required'
    }
    if (!v && !customField.required) {
      return ''
    }
    return validator(v, customField)
      ? undefined
      : customField.errorMessage || `Invalid ${customField.name}`
  }
}
function getCustomFieldInputType(customField) {
  const inputTypes = {
    string: 'text',
    email: 'email',
    regex: 'text',
    'select-single': 'select',
    'select-multi': 'select',
    number: 'number',
    float: 'number',
    link: 'text',
  }
  return inputTypes[customField.fieldType] || 'text'
}
const isValidRegEx = v => {
  try {
    new RegExp(v)
  } catch {
    return 'Invalid Regex'
  }
}
module.exports = {
  customFieldValidators,
  getCustomFieldInputType,
  getFinalFormValidator,
  isValidRegEx,
  FIELD_TYPES,
}
