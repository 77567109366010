import { useEffect, useState } from 'react'
/*

  Theme.js set via context.


  Routing via memory router - esp for embed 
  https://reactrouter.com/web/api/MemoryRouter
*/

import SlotPicker from '../components/Booking/SlotPicker'
import { useAvailability } from '../components/Booking/hooks'
import BookingSummary from '../components/Booking/BookingSummary'
import { useHistory, useParams } from 'react-router'
import {
  ClockIcon,
  LocationMarkerIcon,
  GlobeAltIcon,
} from '@heroicons/react/solid'
import humanizeDuration from 'humanize-duration'
import { Modal } from '../components/Modal'
import { Pill, PillDropdown } from '../components/Pill'
import { NavList, NavListItem } from '../components/List'
import { useDisclosure } from '../hooks/disclosure'
import { useDetectDevice } from '../hooks/useDetectDevice'
function EventTypes({ eventTypes, onClick }) {
  return (
    <NavList>
      {eventTypes.map(st => {
        return (
          <NavListItem key={st.id} onClick={() => onClick(st)}>
            <div className="w-full text-center">
              {humanizeDuration(st.slotSize * 60000) === st.title ? (
                <>{st.title}</>
              ) : (
                <>
                  {st.title} {humanizeDuration(st.slotSize * 60000)}
                </>
              )}
            </div>
          </NavListItem>
        )
      })}
    </NavList>
  )
}
export default function Booking({ account, calendar, hideSummary }) {
  const params = useParams()
  const eventType =
    calendar.eventTypes.length > 1
      ? calendar.eventTypes.find(st => st.slug === params.eventType) ||
        calendar.eventTypes[0]
      : calendar.eventTypes[0]
  const { availability, month, setMonth, refresh } = useAvailability(
    account,
    calendar,
    eventType
  )
  const [eventLocation, setLocation] = useState(
    eventType?.location?.length ? eventType?.location?.[0] : null
  )
  const { isOpen, onClose, onOpen } = useDisclosure()
  const history = useHistory()
  const [hideHeader, setHideHeader] = useState(false)
  const { isMobile } = useDetectDevice()
  return (
    <div
      className={`${
        !hideHeader ? 'mt-3' : ''
      } flex flex-col space-y-6 max-w-4xl mx-auto lg:px-8`}
    >
      <>
        {!hideHeader && (
          <div className="space-y-2 md:space-x-0 p-4 md:p-0">
            {!hideSummary && (
              <BookingSummary calendar={calendar} account={account} />
            )}
            <div className="flex flex-col">
              <PillDropdown icon={GlobeAltIcon}>
                {calendar.timezone}
              </PillDropdown>
              <PillDropdown
                icon={ClockIcon}
                options={calendar.eventTypes.map(evt => ({
                  value: evt.id,
                  name: evt.title,
                  ...evt,
                }))}
                onChange={evt =>
                  history.push(`/${account.slug}/${calendar.slug}/${evt.slug}`)
                }
              >
                {humanizeDuration(eventType?.slotSize * 60000 || 60 * 60000) ===
                eventType?.title ? (
                  <>{eventType?.title}</>
                ) : (
                  <>
                    {humanizeDuration(
                      eventType?.slotSize * 60000 || 60 * 60000
                    )}
                    {' - '}
                    {eventType?.title}
                  </>
                )}
              </PillDropdown>
              {eventType?.location.length > 0 && (
                <PillDropdown
                  icon={LocationMarkerIcon}
                  options={eventType?.location.map(({ id, type }) => ({
                    value: id,
                    name: type,
                  }))}
                  onChange={({ value }) =>
                    setLocation(
                      eventType.location.find(evt => evt.id === value)
                    )
                  }
                >
                  {eventLocation?.type}
                </PillDropdown>
              )}
            </div>
          </div>
        )}
      </>
      <SlotPicker
        calendar={calendar}
        availability={availability}
        onChangeMonth={m => setMonth(m)}
        month={month}
        onSelectDate={d => {
          if (d && isMobile) {
            setHideHeader(true)
          } else {
            setHideHeader(false)
          }
        }}
        onSelectSlot={s => {
          history.push(
            `/${account.slug}/${calendar.slug}/${eventType?.slug || 'custom'}/${
              s.start
            }/${s.end}?tz=${encodeURIComponent(s.timezone)}`
          )
        }}
      />
      <Modal title="Select a duration" isOpen={isOpen} onClose={onClose}>
        <div className="pt-4">
          {calendar.eventTypes.length > 1 && (
            <EventTypes
              eventTypes={calendar.eventTypes}
              onClick={st => {
                history.push(`/${account.slug}/${calendar.slug}/${st.slug}`)
                onClose()
              }}
            />
          )}
        </div>
      </Modal>
    </div>
  )
}
