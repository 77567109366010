import { useParams, useHistory } from 'react-router'
import { client, useReservation } from '../components/Booking/hooks'
import moment from 'moment-timezone'
import { XCircleIcon, ClockIcon, CalendarIcon,  CheckCircleIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import { useDisclosure } from '../hooks/disclosure'
import { ConfirmButton } from '../components/Button'
import { Modal } from '../components/Modal'
import { BookingNotFound } from '../components/Booking/BookingNotFound'

const RE_URL = /\w+:\/\/\S+/g

function linkify(str) {
  let match
  const results = []
  let lastIndex = 0
  while ((match = RE_URL.exec(str))) {
    const link = match[0]
    if (lastIndex !== match.index) {
      const text = str.substring(lastIndex, match.index)
      results.push(<span key={results.length}>{text}</span>)
    }
    results.push(
      <a
        key={results.length}
        href={link}
        className="hover:text-blue-400 text-blue-600 p2-4"
        target="_blank"
      >
        {link}
      </a>
    )
    lastIndex = match.index + link.length
  }
  if (results.length === 0) {
    return str
  }
  if (lastIndex !== str.length) {
    results.push(<span key={results.length}>{str.substring(lastIndex)}</span>)
  }
  return results
}

export default function Cancel({ account }) {
  const { reservationId } = useParams()
  const reservation = useReservation(account.id, reservationId)
  const [reason, setReason] = useState('')
  const { onOpen, isOpen, onClose } = useDisclosure()
  const [successCancel, setSuccessCancel] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  const history = useHistory()
  if (reservation?.data?.notFound) {
    return <BookingNotFound account={account} />
  }
  return (
    <div className="max-w-2xl mx-auto p-8 flex flex-col">
      <div className="bg-white shadow-lg h-full space-y-4 space-y-3">
        <div className="p-8">
          <XCircleIcon className="w-16 h-16 m-auto text-red-400" />
          <h1 className="text-3xl tracking-tight font-extrabold text-gray-700 sm:mt-5 lg:mt-6 text-center">Cancel Booking</h1>
          <div className="text-2xl tracking-tight pt-6">{reservation?.data?.title}</div>
          <div className="flex py-2">
            <CalendarIcon className="w-6 h-6 text-gray-600" />
            <div className="pl-3 text-base text-warmGray-600 self-center">
              {moment(reservation?.data?.start).format('dddd, MMMM Do YYYY')}
            </div>
          </div>
          <div className="flex py-2">
              <ClockIcon className="w-6 h-6 text-gray-600" />
              <div className="pl-3 text-base text-warmGray-600 self-center">
                {moment(reservation?.data?.start).format('h:mm a')} - {moment(reservation?.data?.end).format('h:mm a z')}
              </div>
            </div>
          {reservation?.data?.description && (
            <div>
              <div> </div>
              <p className="text-md">{linkify(reservation.data.description)}</p>
            </div>
          )}
        </div>
        <div className="flex flex-col px-20">
          <span>Reason:</span>
          <textarea
            className="border border-gray-200"
            placeholder="Sorry I can no longer make it"
            onChange={e => setReason(e.target.value)}
          >
            {reason}
          </textarea>
        </div>
        <div className="w-full">
          <div className="flex flex-row justify-between w-full">
            <Link
              to={`/${account.slug}/r/${reservationId}/reschedule`}
              className="text-black p-4 flex-1 cursor-pointer text-center"
            >
              Reschedule instead?
            </Link>
          </div>
          <div className="flex flex-row justify-between w-full">
            <Link
              onClick={async () => {
                try {
                  await client.post(
                    `/accounts/${account.id}/reservations/${reservationId}/cancel`,
                    {
                      reason: reason || '',
                    }
                  )
                  setSuccessCancel(true)
                  onOpen()
                } catch (e) {
                  console.error(e)
                  setSuccessCancel(false)
                  if (e?.response?.data?.message === 'Reservation Not Found') {
                    setErrorMessage('')
                  } else {
                    setErrorMessage(e?.response?.data?.message || '')
                  }
                  onOpen()
                }
              }}
              className="text-white bg-red-500 p-4 border-r-2 flex-1 cursor-pointer text-center border-gray-200"
            >
              Cancel
            </Link>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        icon={
          successCancel ? (
            <CheckCircleIcon className="w-8 h-8 text-green-600" />
          ) : (
            <XCircleIcon className="w-8 h-8 text-red-600" />
          )
        }
        title={successCancel ? 'Cancelled booking' : 'Unable to cancel booking'}
        onClose={onClose}
        actions={
          <>
            {successCancel ? (
              <ConfirmButton onClick={() => history.push(`/${account.slug}`)}>
                Continue
              </ConfirmButton>
            ) : (
              <ConfirmButton onClick={onClose}>Close</ConfirmButton>
            )}
          </>
        }
      >
        <p>{errorMessage}</p>
      </Modal>
    </div>
  )
}
