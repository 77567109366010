import { useDisclosure } from '../hooks/disclosure'
import { NavList, NavListItem } from './List'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { Modal } from './Modal'
import { rest } from 'lodash'
export function Pill({ children, className, ...rest }) {
  return (
    <div
      {...rest}
      className={`${
        rest.onClick ? 'cursor-pointer hover:no-underline underline' : ''
      } space-x-2 flex self-start items-center rounded-sm py-2 text-xs font-medium text-gray-700 mr-2 mt-2`}
    >
      {children}
    </div>
  )
}

export function PillDropdown({
  label,
  children,
  icon: Icon,
  className,
  disabled,
  options,
  onChange,
  ...rest
}) {
  const modal = useDisclosure()
  if (!options) return null
  return (
    <>
      <div
        className={`${
          options?.length > 1 && !disabled
            ? 'cursor-pointer hover:underline'
            : ''
        }  space-x-2 flex capitalize self-start items-center py-2 text-xs font-medium text-gray-700 mr-2 mt-2`}
        onClick={options.length > 1 && modal.onOpen}
      >
        {!!Icon && (
          <Icon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        )}
        <div>{children}</div>
        {options.length > 1 && (
          <ChevronDownIcon className="h-4 w-4 text-gray-800" />
        )}
      </div>
      <Modal title={label} isOpen={modal.isOpen} onClose={modal.onClose}>
        <NavList>
          {options.map(opt => (
            <NavListItem
              onClick={() => {
                onChange(opt)
                modal.onClose()
              }}
            >
              {opt.name}
            </NavListItem>
          ))}
        </NavList>
      </Modal>
    </>
  )
}

PillDropdown.defaultProps = {
  options: [],
}
