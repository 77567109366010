import classNames from './classNames'
export const XSmallButton = props => (
  <button
    type="button"
    className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
    {...props}
  />
)

export const SmallButton = props => (
  <button
    type="button"
    className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
    {...props}
  />
)

export const MediumButton = props => (
  <button
    type="button"
    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
    {...props}
  />
)

export const LargeButton = props => (
  <button
    type="button"
    className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-green-700 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
    {...props}
  />
)

export const XLargeButton = props => (
  <button
    type="button"
    className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
    {...props}
  />
)

export const FullWidthButton = ({
  children,
  submitting,
  disabled,
  ...rest
}) => (
  <button
    type="button"
    className={classNames(
      submitting && 'cursor-wait',
      disabled && !submitting && 'cursor-not-allowed',
      'flex flex-row w-full justify-center text-center items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-500 hover:bg-indigo-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
    )}
    {...rest}
  >
    {submitting && (
      <svg
        class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    )}
    {children}
  </button>
)

export const ConfirmButton = ({ className, disabled, ...rest }) => (
  <button
    type="button"
    disabled={disabled}
    className={`inline-flex justify-center w-full rounded-xl border border-transparent shadow-sm px-4 py-2 bg-black text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm ${className} ${disabled &&
      'bg-grey-700'}`}
    {...rest}
  />
)
export const SuccessButton = ({ className, disabled, ...rest }) => (
  <button
    type="button"
    disabled={disabled}
    className={`inline-flex justify-center w-full rounded-xl border border-transparent shadow-sm px-4 py-2 bg-black text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm ${className} ${disabled &&
      'bg-grey-700'}`}
    {...rest}
  />
)
export const DangerButton = ({ className, disabled, ...rest }) => (
  <button
    type="button"
    disabled={disabled}
    className={`inline-flex justify-center w-full rounded-xl border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-400 sm:text-sm ${className} ${disabled &&
      'bg-grey-700'}`}
    {...rest}
  />
)
export const CancelButton = ({ className, ...rest }) => (
  <button
    type="button"
    className={`text-black inline-flex justify-center w-full rounded-xl border border-gray-900 shadow-sm px-4 py-2 bg-white-600 text-base font-medium hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm ${className}`}
    {...rest}
  />
)

export const WithGoogle = ({ text }) => (
  <>
    {text && <span className="px-4">{text}</span>}
    <svg width="27px" height="27px" viewBox="0 0 27 27">
      <g
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
        transform="translate(-482.000000, -579.000000)"
      >
        <g id="Sign-In-" transform="translate(375.000000, 208.000000)">
          <g transform="translate(90.000000, 355.000000)">
            <g id="Google" transform="translate(17.000000, 16.000000)">
              <g id="google-icon">
                <path
                  d="M18.72,7.23 L22.53,3.51 C20.19,1.335 17.145,0 13.5,0 C8.22,0 3.66,3.03 1.44,7.44 L5.805,10.83 C6.9,7.575 9.93,5.22 13.5,5.22 C16.035,5.22 17.745,6.315 18.72,7.23 Z"
                  id="Shape"
                  fill="#EA4335"
                ></path>
                <path
                  d="M26.175,11.04 L14.0201349,11.0400002 L14.0201349,16.0500002 L20.94,16.05 C20.79,17.295 19.98,19.17 18.18,20.43 L22.44,23.73 C24.99,21.375 26.46,17.91 26.46,13.8 C26.46,12.69 26.37,11.88 26.175,11.04 Z"
                  id="Shape"
                  fill="#4285F4"
                ></path>
                <path
                  d="M5.82,16.17 C5.535,15.33 5.37,14.43 5.37,13.5 C5.37,12.57 5.535,11.67 5.805,10.83 L1.44,7.44 C0.525,9.27 0,11.325 0,13.5 C0,15.675 0.525,17.73 1.44,19.56 L5.82,16.17 L5.82,16.17 Z"
                  id="Shape"
                  fill="#FBBC05"
                ></path>
                <path
                  d="M13.5,27 C17.145,27 20.205,25.8 22.44,23.73 L18.18,20.43 C17.04,21.225 15.51,21.78 13.5,21.78 C9.93,21.78 6.9,19.425 5.82,16.17 L1.455,19.56 C3.675,23.97 8.22,27 13.5,27 L13.5,27 Z"
                  id="Shape"
                  fill="#34A853"
                ></path>
                <polygon id="Shape" points="0 0 27 0 27 27 0 27"></polygon>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </>
)

export const WithOutlook = ({ text }) => (
  <>
    {text && <span className="px-4">{text}</span>}
    <svg width="21px" height="21px" xmlns="http://www.w3.org/2000/svg">
      <g fill-rule="nonzero" fill="none">
        <path fill="#F35325" d="M0 0h10v10H0z"></path>
        <path fill="#81BC06" d="M11 0h10v10H11z"></path>
        <path fill="#05A6F0" d="M0 11h10v10H0z"></path>
        <path fill="#FFBA08" d="M11 11h10v10H11z"></path>
      </g>
    </svg>
  </>
)
