import { useContext } from 'react'
import PageLayout from '../landing-page/PageLayout'
import { BlogDataContext } from '../contexts'
import { NotionRenderer } from 'react-notion-x'
import moment from 'moment'
export default function BlogPage() {
  const blogData = useContext(BlogDataContext)
  return (
    <PageLayout
      title={blogData.post.name}
      subTitle={
        <>
          <span className="text-sm">
            Published - {moment(blogData.post.date).format('DD/MM/YY')}
          </span>
          <div className="mt-4 flex flex-row space-x-3">
            {blogData.post.tags.map(t => (
              <div
                key={t}
                className="text-xs bg-blue-400 text-white rounded-full py-2 px-4 capitalize"
              >
                {t}
              </div>
            ))}
          </div>
        </>
      }
    >
      <a href="/blog" className="text-sm text-gray-500">
        Back to Blog posts
      </a>
      <NotionRenderer
        recordMap={blogData.blogBlockMap}
        fullPage={true}
        darkMode={false}
      />
    </PageLayout>
  )
}
